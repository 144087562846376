import * as React from "react";
import { useState, useEffect, useCallback } from 'react';
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import TextPanelsDisplay from "./TextPanelsDisplay";
import CharacterManager from "./CharacterManager";
import StoryboardPanel from "./StoryboardPanel";
import ApiManager from './ApiManager';
import Button from "@cloudscape-design/components/button";

import { imageModels, imageStyles, textModels } from '../common/constants';
import SettingsModal from "./SettingsModal";



const Layout = (props) => {
  
  const [selectedModels, setSelectedModels] = useState({
    text: textModels[0],
    image: imageModels[0],
    style: imageStyles[0]
  })
  
  const SAMPLE_TEXT = "The dog was ready to go on a walk.He went outside and had a great time on the hike. He thought, I am very happy to be in the field! After sometime, he went to find his human mom and she was so proud of him because of the gift he made her: a beautiful yellow flower. Then, they came back home and he thought: it was a beautiful day for me!"
  const SAMPLE_TEXTS = [
    {title: "The dog ride", script: "The dog was ready to go on a walk.He went outside and had a great time on the hike. He thought, I am very happy to be in the field! After sometime, he went to find his human mom and she was so proud of him because of the gift he made her: a beautiful yellow flower. Then, they came back home and he thought: it was a beautiful day for me!"},
    {title: "Maya the inventor", script: `Once upon a time, in a bustling city nestled between majestic mountains and an endless ocean, lived a young inventor named Maya. She had always been captivated by the boundless possibilities of technology and the wonders it could create. Her days were spent tinkering in her small workshop, dreaming up inventions that could change the world.
      Maya's biggest dream was to build a machine that could bring light to the darkest corners of the world. She envisioned a device powered by renewable energy that could provide electricity to remote villages, where children studied by candlelight and families gathered around fires in the evenings.
      
      With unyielding determination, Maya set out to create her invention. Days turned into weeks, weeks into months, and months into years. She faced numerous setbacks, encountered countless challenges, and often felt the weight of self-doubt creeping in.
      
      But Maya's spirit remained unbroken. She found inspiration in the resilience of nature and the unwavering support of her community. Whenever she gazed at the stars twinkling above the mountains, she was reminded of the infinite possibilities awaiting discovery.
      
      After years of tireless effort, Maya finally unveiled her invention—a compact, solar-powered generator capable of providing sustainable energy to remote areas. With its sleek design and efficient technology, it could harness sunlight during the day and illuminate the nights with a warm, radiant glow.
      
      Eager to share her creation with the world, Maya traveled to distant lands where her invention was needed most. She visited villages where the arrival of her machine brought tears of joy to the faces of children seeing electric light for the first time. Families gathered around it, sharing stories and experiences long after the sun had set, feeling the warmth of unity and hope it brought.
      
      Word of Maya's invention spread far and wide, capturing the attention of innovators, philanthropists, and leaders worldwide. Her creation sparked a movement, inspiring a wave of sustainable technologies and empowering communities globally.
      
      Maya's journey taught her that the most extraordinary accomplishments often stem from unwavering passion, resilience in the face of adversity, and a belief in the power of one's dreams. Her story became a beacon of hope, illustrating that even the smallest spark of an idea can illuminate the world with boundless light and inspiration.`
    }
  ]

  const [selectedTemperature, setSelectedTemperature] = useState(0.2);
  const [numberOfPanels, setNumberOfPanels] = useState(7);
  
  const [customText, setCustomText] = useState(SAMPLE_TEXT);
  const [characters, setCharacters] = useState([])
  const [isCharacterSectionExpanded, setIsCharacterSectionExpanded] = useState(false);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(new Array(numberOfPanels).fill(true));
  const [experimental, setExperimental] = useState(false)

  const setImageLoading = (index, is_loading) => {
    const newImagesLoading = [...imagesLoading];
    newImagesLoading[index] = is_loading;
    setImagesLoading(newImagesLoading);

    setData(prevData => {
      const newData = [...prevData];
      newData[index].loading = is_loading;
      return newData;
    }) 
    console.log(`Setting ${index} loading to ${is_loading}`)
  }

  const setImageData = (index, image) => {
    console.debug(`Setting image data for ${index} with ${image.substring(image.length - 10)}`)
    setData(prevData => {
        const newData = [...prevData];
        newData[index].image = image;
        if(image !== ""){
          newData[index].img_version += 1;
        }
        newData[index].loading = false;
        return newData;
    }) 
  }
  const generateCharacters = async (story) => {
    const characters = await extractCharacters(story);
    console.log(`Extracted characters: ${JSON.stringify(characters)}`)
    characters.forEach((character, index) => {
        character.id = index;
    });
    setCharacters(characters)
    return characters
  }

  const generateStory = async (story, characters) => {
    const { panels, simplePanels } = await getPanels(story, characters)

    let updatedData = panels.map((panel, index) => ({
        id: index,
        panel: panel,
        simplePanel: simplePanels[index],
        prompt: null, 
        image: null,
        loading: true,
        img_version: 0,
    }));
    
    setData(updatedData);

    // Now generate prompts and images
    for (let i = 0; i < updatedData.length; i++) {
        setImageLoading(i, true)
        try {
          console.log(`Generating sd_prompt for panel ${i}`)
          const promptResponse = await getSdPromptData(story, updatedData[i].panel, characters);
          const prompt = JSON.parse(promptResponse.body).sd_prompt
          updatedData[i].prompt = prompt;
          
          setData([...updatedData]);

          const image = await generateImage(updatedData[i]);
          setImageData(i, image.body);
        } catch (error) {
          console.error(`Error generating image for panel ${i}:`, error);
        } finally {
          setImageLoading(i, false);
        }
    }
    
    // const imagePromises = updatedData.map((item, index) => { 
    //     setImageLoading(index, true)
    //     generateImage(item).then(image => {
    //         setImageData(index, image.body)
    //     })
    //     setImageLoading(index, false)
    //     return true
    // });
    // await Promise.all(imagePromises);
  }

  const generate = async (story="", generateNewCharacters=true) => {
    let rawStory = story;
    let generatedStory = "";
    let storyCharacters = characters;
    
    setData([]);
    if(generateNewCharacters || characters.length === 0) {
      storyCharacters = await generateCharacters(rawStory);
    }
    if(rawStory === "") {
      rawStory = customText;
    }
    console.debug(`generateNewCharacters: ${generateNewCharacters}`);
    console.debug(`rawStory: ${rawStory}`);
    
    generatedStory = await generateStory(rawStory, storyCharacters);
    return generatedStory;
  }

  const regenImage = async (index) => {
    setImageData(index, "")
    setImageLoading(index, true)

    try {
      const image = await generateImage(data[index]);
      setImageData(index, image.body)
    } catch (error) {
      console.error(`Error regenerating image for panel ${index}:`, error);
    } finally {
      setImageLoading(index, false);
    }
    // generateImage(data[index]).then(image => {

    //     setImageData(index, image.body)
    //     setImageLoading(index, false)
    //   })
    
  }

  const extractCharacters = async (story) => {
    console.log(`Extracting characters`)
    setLoading(true)
    try {
        const charactersResponse = await ApiManager.extractCharacters({
            text: story,
            model_id_text: selectedModels.text.value,
            temperature: selectedTemperature
        });
        let charactersBody = JSON.parse(charactersResponse.body)
        console.log(`panelsResponse: ${charactersBody.characters}`);
        setLoading(false)
        return charactersBody.characters
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
        setLoading(false);
    } finally {
        setLoading(false);
    }
    return null
  }

  const getPanels = async (story, characters) => {
    setLoading(true)
    try {
        // const panelsResponse = await API.post(apiName, generatePanelsPath, myInit);
        const panelsResponse = await ApiManager.generatePanels({
            text: story,
            model_id_text: selectedModels.text.value,
            characters: characters,
            temperature: selectedTemperature,
            nb_panels: numberOfPanels
        });
        let panelsBody = JSON.parse(panelsResponse.body)
        console.log(`panelsResponse: ${JSON.stringify(panelsBody)}`);
        setLoading(false)
        return panelsBody
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
        setLoading(false);
    } finally {
        setLoading(false);
    }
    return null
  }

  const getSdPromptData = async (story, panel, characters) => {
    let prompt_version = "1"
    if(experimental) {
      prompt_version = "2"
    }
    try {
        let apiResponse = await ApiManager.generateImagePrompts({
            story: story,
            panel: panel,
            model_id_text: selectedModels.text.value,
            characters: characters,
            style: selectedModels.style,
            prompt_version: prompt_version
        });
        return apiResponse
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
    }
  }

  const generateImage = async (item) => {
    console.log(`Generating image for panel ${item.panel}`)
    const image = await getImageData(item.panel, item.prompt)
    return image
  }

  const getImageData = async (panel, sd_prompts) => {  
    try {
        let apiResponse = await ApiManager.generateImage({
            panel: panel,
            sd_prompts: JSON.parse(sd_prompts),
            model_id_image: selectedModels.image.value,
            style: selectedModels.style
        });
        // console.log(`apiResponse: ${JSON.stringify(apiResponse)}`)
        return apiResponse
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
    }
    
  };

  // const getUserGroups = (user) => {
  //   return user.signInUserSession.idToken.payload["cognito:groups"];
  // }

  // Effect to automatically expand the Character Manager when there is at least one character
  useEffect(() => {
    if (characters.length > 0) {
      setIsCharacterSectionExpanded(true);
    }
  }, [characters]);

  const handleModelChange = useCallback((modelType, newModel) => {
    setSelectedModels(prev => ({ ...prev, [modelType]: newModel }));
  }, []);

  return (
    <>
    <Container
      fitHeight
      header={
        <Header
        variant="h2"
        description="Let's start by creating an awesome story!"
        >
          Storyboard manager
        </Header>
      }>
        
        {/* <div className="text-panels-display" id="model-options"> */}
        <SettingsModal
          isOpen={props.isSettingsModalOpen}
          onClose={() => props.setIsSettingsModalOpen(false)}
          selectedModels={selectedModels}
          onModelChange={handleModelChange}
          temperature={selectedTemperature}
          onTemperatureChange={setSelectedTemperature}
          numberOfPanels={numberOfPanels}
          onNumberOfPanelsChange={setNumberOfPanels}
          experimental={experimental}
          onExperimentalChange={setExperimental}
        />
          {/* <ModelsOptions 
            selectedModels={selectedModels}
            onModelChange={handleModelChange}
            temperature={selectedTemperature}
            onTemperatureChange={setSelectedTemperature}
            numberOfPanels={numberOfPanels}
            onNumberOfPanelsChange={setNumberOfPanels}
            experimental={experimental}
            onExperimentalChange={setExperimental}
            /> */}
        {/* </div> */}
        <div className="text-panels-display" id="text-panel">
          <div>
            <p>Use sample scripts or enter your script </p>
            {SAMPLE_TEXTS.map((text, index) => (
              <Button
                variant="primary"
                key={index}
                onClick={() => setCustomText(text.script)}
                >{text.title}</Button>
            ))}
          </div>
          <div className="data-panel">
            <TextPanelsDisplay
              selectedTextModel={selectedModels.text}
              textModels={textModels}

              selectedImageModel={selectedModels.image}
              imageModels={imageModels}

              selectedStyle={selectedModels.style}
              imageStyles={imageStyles}

              selectedTemperature={selectedTemperature}

              customText={customText}
              setCustomText={setCustomText}

              setCharacters={setCharacters}
              characters={characters}

              setData={setData}
              data={data}

              numberOfPanels={numberOfPanels}
              setNumberOfPanels={setNumberOfPanels}

              generate={generate}

              loading={loading}
              imagesLoading={imagesLoading}
            />
            <ExpandableSection
              headerText={`Characters (${characters.length})`}
              expanded={isCharacterSectionExpanded}
              onChange={({ detail }) => setIsCharacterSectionExpanded(detail.expanded)}
              >
                <CharacterManager 
                  characters={characters}
                  customText={customText}
                  selectedTextModel={selectedModels.text}
                  selectedTemperature={selectedTemperature}
                  setCharacters={setCharacters}
                  generate={generate}
                />
            </ExpandableSection>
            <ExpandableSection
              headerText="Storyboard..."
              >
                <StoryboardPanel
                  data={data}
                  regenImage={regenImage}
                />
              </ExpandableSection>
          </div>
        </div>
        <ExpandableSection
          headerText="More options..."
        >
          <p>Coming soon...</p>
        </ExpandableSection>
      </Container>
    </>
  )

};

export default Layout;