import './App.css';
import Layout from './components/Layout';
import ContentLayout from "@cloudscape-design/components/content-layout";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TopNavigation } from '@cloudscape-design/components';
import { VERSION } from './version';


export const signInWithSSO = async () => {

    try {
      const user = await Auth.federatedSignIn();
      console.log("signInWithSSO OK", user)
      return user;
    } catch (e) {
      throw e;
    }
};


const useAuth = () => {
  const [user, setUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);
  
  const fetchUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUserAttributes(userData.signInUserSession.idToken.payload);
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
      const userData = await signInWithSSO();
      setUser(userData);
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setUserAttributes(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

    return { user, userAttributes, handleSignOut}
  }


function App() {
  // const isAuthenticated = () => {
  //   const accessToken = sessionStorage.getItem('accessToken');
  //   return !!accessToken;
  // };

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { user, userAttributes, handleSignOut } = useAuth();



  

  const handleUtilityClick = useCallback((e) => {
    if (e.detail.id === 'logout') {
      console.log('logging out');
      handleSignOut();
    }
  }, [handleSignOut]);

  const memoizedTopNavigation = useMemo(() => (
    <TopNavigation
      identity={{
        href: "#",
        title: "GenAI Storyboard Generator",
        logo: {
          src: "/Storyboard.svg",
          alt: "Logo"
        }
      }}
      utilities={[
        {
          type: "button",
          text: "Learn more",
          href: "https://w.amazon.com/bin/view/Users/fclanet/storyboardgen/",
          external: true,
          externalIconAriaLabel: "(opens in a new tab)"
        },
        {
          type: "button",
          text: `v${VERSION}`,
        },
        {
          type: "button",
          iconName: "settings",
          ariaLabel: "Settings",
          title: "Settings",
          onClick: () => setIsSettingsModalOpen(true)
        },
        {
          type: "menu-dropdown",
          text: userAttributes ? (`${userAttributes.given_name} ${userAttributes.family_name}`) : ("Not connected"),
          description: userAttributes ? userAttributes.email : "",
          iconName: "user-profile",
          items: [
            { id: "logout", text: "Log out" }
          ],
          onItemClick: handleUtilityClick
        }
      ]}
    />
  ), [userAttributes, handleUtilityClick])
  return (<>
    {memoizedTopNavigation}
    <ContentLayout
    >{user ? (
      <div className="App">
        <div className='AppContent'>
          <Layout 
            user={user}
            isSettingsModalOpen={isSettingsModalOpen}
            setIsSettingsModalOpen={setIsSettingsModalOpen}
          />
        </div>
      </div>
    ):(<p>You need to log in first.</p> )}
    </ContentLayout>
    </>
  );
}

export default App;
