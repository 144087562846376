const cdkEnv = require('./cdk-env-output.json');
const awsmobile = {
  "aws_project_region": cdkEnv.StoryboardGeneratorStack.ProjectRegion,
  "aws_cognito_region": cdkEnv.StoryboardGeneratorStack.ProjectRegion,
  "aws_user_pools_id": cdkEnv.StoryboardGeneratorStack.UserPoolIdOutput,
  "aws_user_pools_web_client_id": cdkEnv.StoryboardGeneratorStack.UserPoolWebClientIdOutput,
  "aws_cloud_logic_custom": [
    {
      "name": "models",
      "endpoint": "https://gzpoyeph91.execute-api.us-east-1.amazonaws.com/dev",
      "region": "us-east-1"
    },
    {
      name: 'genai',
      endpoint: `${cdkEnv.StoryboardGeneratorStack.EndpointOutput}v1/`,
      region: cdkEnv.StoryboardGeneratorStack.ProjectRegion
    }
  ],
  "oauth": {
    "domain": "storyboard-generator.auth.us-east-1.amazoncognito.com",
    "scope": ["openid", "profile", "email"],
    "redirectSignIn": "https://storyboardgen.fclanet.people.aws.dev",
    "redirectSignOut": "https://storyboard-generator.auth.us-east-1.amazoncognito.com/logout",
    "responseType": "token",
    "providers": ["Federate"]
  }
};
export default awsmobile;
