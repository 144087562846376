// ImageComponent.js
import Spinner from "@cloudscape-design/components/spinner";
import Button from "@cloudscape-design/components/button";

const ImageComponent = ({ loading, image, img_version, onRegenClick, index }) => {

  console.debug(`rendering image ${index} with image: ${image?.slice(-20)} (version ${img_version})`)
  return (
    <div className="image-container">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <img 
            src={`data:image/jpeg;base64,${image}`}
            width="300px"
            alt=""
          />
          <span className="regen-icon">
            <Button variant="icon" iconName="refresh" aria-label="Refresh" onClick={onRegenClick}  >
              Refresh picture
            </Button>
          </span>
          {/* <p>
            <span>img_version: {img_version}</span>
          </p>
          <p>
            <span>img: {image?.slice(-20)}</span>
          </p> */}
        </>
      )}
    </div>
  );
};

export default ImageComponent;
