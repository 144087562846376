import { useEffect } from 'react';
import Button from "@cloudscape-design/components/button"
import Textarea from "@cloudscape-design/components/textarea";

const TextPanelsDisplay = (props) => {

    const handleCustomTextChange = (event) => {
        props.setCustomText(event.detail.value);
    }
    const handleFetchData = () => {
        props.setData([])
        props.setCharacters([])
        props.generate(props.customText);
    }
    useEffect(() => {
    }, [props.data, props.imagesLoading])
    
    return (
            <>
                <Textarea
                    className="custom-text"
                    value={props.customText}
                    onChange={handleCustomTextChange}
                    placeholder="Enter custom text here..."
                />

                {props.characters.length<1 && props.data.length<1 && props.loading ? (<>
                    <span aria-live="polite" aria-label="Loading table content.">
                        <Button loading variant="primary">Generating storyboard...</Button>
                    </span>
                </>
                ) : (<>
                        <Button onClick={handleFetchData}>
                            Send your story
                        </Button>
                        <div>
                        </div>
                    </>
                    )}
                    
            </>

    );
};

export default TextPanelsDisplay;