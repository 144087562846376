import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

const GENAI_API_NAME = "genai"
const ENDPOINTS = {
  EXTRACT_CHARACTERS: () => `extract-characters`,
  GENERATE_PANELS: () => `generate-panels`,
  GENERATE_IMAGE_PROMPTS: () => `generate-image-prompts`,
  GENERATE_IMAGE: () => `generate-image`,
  
};

// console.log('idToken: ', idToken.jwtToken);
    // const { accessToken, idToken } = (await fetchAuthSession)
      // const session = await Auth.currentSession();
      // const token = session.accessToken.jwtToken;
      // console.log('jwt Token:', token.toString());
      // console.log('ID Token:', session.tokens.idToken.toString());

const retrieveTokens = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { idToken } = user.signInUserSession;
    // console.log(`Bearer ${JSON.stringify(idToken.jwtToken)}`);
    return `Bearer ${idToken.jwtToken.toString()}`;
  }
  catch (e) { console.log(e); }
}


class ApiManager {

  // constructor(){}

  private static async getInit () {
    const token = await retrieveTokens()
    return {
      headers: {Authorization: token},
      body: {}
    }
  }

  // public static post = async (endpoint: string, body: any) => {
  //   const init = await this.getInit();
  //   init.body = body;
  //   return API.post(GENAI_API_NAME, endpoint, init); 
  // }

  public static extractCharacters = async (body: any) => {
    const apiName = GENAI_API_NAME
    const path = ENDPOINTS.EXTRACT_CHARACTERS()
    const init = await this.getInit()
    init.body = body
    return await API.post(apiName, path, init);
  }

  public static generatePanels = async (body: any) => {
    const apiName = GENAI_API_NAME
    const path = ENDPOINTS.GENERATE_PANELS()
    const init = await this.getInit()
    init.body = body
    return await API.post(apiName, path, init);
  }

  public static generateImagePrompts = async (body: any) => {
    const apiName = GENAI_API_NAME
    const path = ENDPOINTS.GENERATE_IMAGE_PROMPTS()
    const init = await this.getInit()
    init.body = body
    return await API.post(apiName, path, init);
  }

  public static generateImage = async (body: any) => {
    const apiName = GENAI_API_NAME
    const path = ENDPOINTS.GENERATE_IMAGE()
    const init = await this.getInit()
    init.body = body
    return await API.post(apiName, path, init);
  }

}
export default ApiManager;