import * as React from "react";
import Select from "@cloudscape-design/components/select";
import Toggle from "@cloudscape-design/components/toggle";

import { imageModels, imageStyles, textModels, temperatureRange, maxPanelCount } from '../common/constants';

const ModelsOptions = ({
    selectedModels,
    onModelChange,
    temperature,
    onTemperatureChange,
    numberOfPanels,
    onNumberOfPanelsChange,
    experimental,
    onExperimentalChange,
}) => {

    // const [userRoles, setUserRoles] = useState(props.userGroups)
    
    // const isUserAdmin = () => {
    //     // console.log(userRoles);
    //     return userRoles.includes("admins");
    // }
    
    return <>
        {/* <div className={"data-panel"}> */}
            <h2>ModelsOptions</h2>
            <div>
                <label>Text model: </label>
                <Select
                    selectedOption={selectedModels.text}
                    onChange={({ detail }) => onModelChange("text", detail.selectedOption)}
                    options={textModels}
                />
            </div>
            {/* {isUserAdmin() || true &&  */}
            { true && 
                <div>
                    <label>Image model: </label>
                    <Select
                        selectedOption={selectedModels.image}
                        onChange={({ detail }) => onModelChange("image", detail.selectedOption)}
                        options={imageModels}
                    />
                </div>
            }
            <div>
                <label>Style preset: </label>
                <Select
                    selectedOption={selectedModels.style}
                    onChange={({ detail }) => onModelChange("style", detail.selectedOption)}
                    options={imageStyles}
                />
            </div>
            <div>
                <label>Temperature: </label>
                <input type='range'
                    min={temperatureRange.min}
                    max={temperatureRange.max}
                    step={temperatureRange.step}
                    onChange={(e) => onTemperatureChange(e.target.value)}
                    value={temperature}
                ></input>
                {temperature}
            </div>
            <div>
                <label>Number of panels: </label>
                <input type='range'
                    min={1}
                    max={maxPanelCount}
                    step={1}
                    onChange={(e) => onNumberOfPanelsChange(e.target.value)}
                    value={numberOfPanels}
                ></input>
                {numberOfPanels}
            </div>
            <Toggle
                onChange={({ detail }) =>
                    onExperimentalChange(detail.checked)
                }
                checked={experimental}
            >
                Experimental mode
            </Toggle>
        {/* </div> */}
    </>
}
export default ModelsOptions