import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Spinner from "@cloudscape-design/components/spinner";
import ImageComponent from "./ImageComponent";

const StoryboardPanel = ({ data, regenImage }) => {

  const displayPrompt = (prompt, type) => {
    const parsedPrompt = JSON.parse(prompt);
    return parsedPrompt[type]
  }

  const handleRegenClick = (index) => {
    regenImage(index)
  }
  return (
    <>
      <ul className="data-list">
          {data?.map((item, index) =>(
              <li key={index} className="list-item">
                {/* Loading: {JSON.stringify(item.loading)} */}
                {/* <div className="image-container">
                  {item.loading? (
                  <Spinner/>
                  ) : (
                      <>
                        <img 
                          src={`data:image/jpeg;base64,${item.image}`}
                          width="150px"
                          alt=""
                        />
                        <span className="regen-icon">
                          <Button variant="icon" iconName="refresh" aria-label="Refresh" onClick={() => handleRegenClick(index)}  >
                            Refresh picture
                          </Button>
                        </span>
                        <span>img_version: {item.img_version}</span>
                      </>
                    )
                  }
                </div> */}
                <ImageComponent
                  loading={item.loading}
                  image={item.image}
                  img_version={item.img_version}
                  onRegenClick={() => handleRegenClick(index)}
                  index={index}
                />
                <p>{item.simplePanel}</p>
                <ExpandableSection
                  headerText="Prompts"
              > {item?.prompt ? (
              <>
                <p>
                  <strong>Positive:</strong> {displayPrompt(item?.prompt, "positive_prompt")}
                </p>
                <p>
                  <strong>Negative:</strong> {displayPrompt(item?.prompt, "negative_prompt")}
                </p>
              </>
              ) : (
              <>
                <p><Spinner/>Generating prompts...</p>
              </>
              )}
                

              </ExpandableSection>
              </li>
          ))}
      </ul>
    </>
  )
}

export default StoryboardPanel