// Text generation models
export const textModels = [
  // { label: "claude-3-sonnet", value: "anthropic.claude-3-sonnet-20240229-v1:0" },
  // { label: "claude-3-sonnet CRI", value: "us.anthropic.claude-3-sonnet-20240229-v1:0" },
  // { label: "claude-3.5-sonnet CRI", value: "us.anthropic.claude-3-5-sonnet-20240620-v1:0" },
  // { label: "claude-3-opus CRI", value: "us.anthropic.claude-3-opus-20240229-v1:0" },
  { label: "claude-3-haiku CRI", value: "us.anthropic.claude-3-haiku-20240307-v1:0" },
  { label: "claude-3-haiku", value: "anthropic.claude-3-haiku-20240307-v1:0" },
  { label: "claude-instant-v1", value: "anthropic.claude-instant-v1" },
  { label: "claude-v2:1", value: "anthropic.claude-v2:1" },
  { label: "claude-v2", value: "anthropic.claude-v2" },
  { label: "claude-v1", value: "anthropic.claude-v1" },
]

export const imageModels = [
  { label: "stability.stable-image-core-v1:0", value: "stability.stable-image-core-v1:0" },
  { label: "stability.sd3-large-v1:0", value: "stability.sd3-large-v1:0" },
  { label: "stability.stable-image-ultra-v1:0", value: "stability.stable-image-ultra-v1:0" },
  { label: "stability.stable-diffusion-xl-v1", value: "stability.stable-diffusion-xl-v1" },
  // { label: "stability.stable-diffusion-xl-v0", value: "stability.stable-diffusion-xl-v0" },
  // { label: "amazon.titan-image-generator-v1", value: "amazon.titan-image-generator-v1" },
]

export const imageStyles = [
  { label: "comic-book", style_preset: "comic-book", positive_prompt: "graphic illustration, comic art, graphic novel art, vibrant, highly detailed", negative_prompt: "photograph, deformed, glitch, noisy, realistic, stock photo, multiple scenes"},
  { label: "anime", positive_prompt: "anime artwork, anime style, key visual, vibrant, studio anime, highly detailed", style_preset: "anime", negative_prompt: "photo, deformed, black and white, realism, disfigured, low contrast"},
  { label: "digital-art", positive_prompt: "concept art, digital artwork, illustrative, painterly, matte painting, highly detailed", style_preset: "digital-art", negative_prompt: "photo, photorealistic, realism, ugly"},
  { label: "line-art", positive_prompt: "professional, sleek, modern, minimalist, graphic, line art, vector graphics", style_preset: "line-art", negative_prompt: "anime, photorealistic, 35mm film, deformed, glitch, blurry, noisy, off-center, deformed, cross-eyed, closed eyes, bad anatomy, ugly, disfigured, mutated, realism, realistic, impressionism, expressionism, oil, acrylic"},
  { label: "cinematic", positive_prompt: "", style_preset: "cinematic", negative_prompt: ""},
  { label: "3d-model", positive_prompt: "", style_preset: "3d-model", negative_prompt: ""},
  { label: "analog-film", positive_prompt: "", style_preset: "analog-film", negative_prompt: ""},
  { label: "photographic", positive_prompt: "", style_preset: "photographic", negative_prompt: ""},
  { label: "enhance", positive_prompt: "", style_preset: "enhance", negative_prompt: ""},
  { label: "fantasy-art", positive_prompt: "", style_preset: "fantasy-art", negative_prompt: ""},
  { label: "isometric", positive_prompt: "", style_preset: "isometric", negative_prompt: ""},
  { label: "low-poly", positive_prompt: "", style_preset: "low-poly", negative_prompt: ""},
  { label: "modeling-compound", positive_prompt: "", style_preset: "modeling-compound", negative_prompt: ""},
  { label: "neon-punk", positive_prompt: "", style_preset: "neon-punk", negative_prompt: ""},
  { label: "origami", positive_prompt: "", style_preset: "origami", negative_prompt: ""},
  { label: "pixel-art", positive_prompt: "", style_preset: "pixel-art", negative_prompt: ""},
  { label: "tile-texture", positive_prompt: "", style_preset: "tile-texture", negative_prompt: ""},
]

// Temperature range for text generation
export const temperatureRange = {
  min: 0,
  max: 1,
  step: 0.1,
};

// Default number of storyboard panels
export const defaultPanelCount = 7;

// Maximum allowed panels
export const maxPanelCount = 20;